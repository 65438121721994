import {
    HIDE_UPDATE_DATA_WINDOW,
    MessageType,
    SET_BODY_OPTIONS,
    SET_CATEGORIES,
    SET_DRIVER_DATA,
    SET_DRIVER_ID, SET_STATE_ORDER,
    SET_SYSTEM_MESSAGE,
    SET_USER_INFO,
    SHOW_UPDATE_DATA_WINDOW,
    SystemActionTypes,
    SystemState, TOGGLE_DISAGREE_ORDER,
    TOGGLE_LOADING_WINDOW,
} from "./@types";
import {driverAPI} from "../../../api/api";
import {EnumStatusResponse} from "../../../api/@types";
import {
    setDriverData,
    setDriverID,
    setStateOrder,
    setSystemMessage,
    showUpdateDataWindow,
    toggleLoadingWindow
} from "./actions";
import {imagePath} from "../../../deployment";
import {OrderStruct} from "../orders/@types";


const initialState: SystemState = {
    update_window_visible:false,
    bodies:[],
    categories:[],
    driver_id : "",
    data: null,
    message: {text:"", type : MessageType.None},
    is_loading: false,
    was_order_id:'',
};

const systemReducer = (state = initialState, action: SystemActionTypes) => {
  switch (action.type) {
    case SET_DRIVER_DATA:
      return {
        ...state,
        data: {...action.data}
      };

    case SET_SYSTEM_MESSAGE: {

        //@ts-ignore
        window.showMessageType = action.messageType;

        return {
            ...state,
            message: {text: action.message, type: action.messageType}
        };
    }

    case SET_DRIVER_ID:
          return {
            ...state,
            driver_id:  action.id
          };

    case SET_BODY_OPTIONS:
          return {
            ...state,
            bodies:  action.bodies
          };

    case SHOW_UPDATE_DATA_WINDOW:
          return {
            ...state,
            update_window_visible:  true,
            was_order_id: action.order_id
          };

    case HIDE_UPDATE_DATA_WINDOW:
          return {
              ...state,
              update_window_visible:  false
          };

    case SET_CATEGORIES:
          return {
              ...state,
              categories:  action.categories
          };


      case SET_USER_INFO:
          return {
              ...state,
              data : {...state.data, garage:action.garage, body_size: action.body_size }
          };
      case TOGGLE_LOADING_WINDOW:
          return {
              ...state,
              is_loading : action.show
          };

      case SET_STATE_ORDER:
          let data = state.data;
          if (data && data.orders) {
              data.orders.forEach(x=> { if (x.id == action.order_id) x.confirmed = true; });
              data.orders = [...data.orders]
          }
          return {
              ...state,
              data : data ? {...data} : data
          };

      case TOGGLE_DISAGREE_ORDER:
          let data_o = state.data;
          if (data_o && data_o.orders) {
              data_o.orders.forEach(x=> { if (x.id == action.order_id) x.disagree = !x.disagree; });
              data_o.orders = [...data_o.orders]
          }
          return {
              ...state,
              data : data_o ? {...data_o} : data_o
          };

    default:
      return state;
  }
};
export const checkOrder = (order_id: string, state: SystemState, dispatch:any) :{result: boolean, error_message: string} => {

    if (!state.data || !state.data.orders) return {result: false, error_message: 'Ошибка: не найден список заказов'};
    let order = state.data.orders.find(x=> x.id == order_id);
    if (!order) return {result: false, error_message: 'Ошибка: заказ не найден'};

    if (state.data.body_option_id =="" || state.data.car_type_id == "" || state.data.garage.adress == "" || !state.driver_id){
        dispatch(showUpdateDataWindow(order_id));
        return {result: false, error_message: ''};
    }

    if ( getCategory(order.car_type_id).maxWeight > getCategory(state.data.car_type_id).maxWeight || // если не соответсвет категория
        (order.body_option_id == "c50b92ab-52d3-11ea-a9c9-00155d8e4e03" // если любой закрытый
         &&    state.data.body_option_id == "1ac6a159-f0f7-11db-9d25-000cf16cef9c" ) || // а у водителя борт
         ( order.body_option_id != "c50b92ab-52d3-11ea-a9c9-00155d8e4e03" && order.body_option_id != state.data.body_option_id) // если не любой закрытый и не соответсвует категория
       )
    return {result: false, error_message: 'Выбранный заказ не соответсвует характеристикам вашего ТС'};

    // if (!state.data.garage ||!state.data.garage.adress || !state.data.body_size || !state.data.body_size.height || !state.data.body_size.width  || !state.data.body_size.length)
    //     return false;

    return {result: true, error_message: 'Вам успешно назначен выбранный заказ, подробная информация придет сообщением в WhatsApp'};
};

export const takeOrder = async (order_id:string, driver_id:string, dispatch:any) => {
    let data : any = await driverAPI.takeOrder(order_id, driver_id);
    if (data.status == 200 && data.data.status === EnumStatusResponse.success) {
        dispatch(setStateOrder(order_id));
        dispatch(setSystemMessage("Вам успешно назначен выбранный заказ", MessageType.Info));
        dispatch(toggleLoadingWindow(true));
        loadOrdersFromServer( driver_id , dispatch).then(()=>{
            dispatch(toggleLoadingWindow(false));
        }).catch(()=> dispatch(toggleLoadingWindow(false)));
    }
    else
        dispatch(setSystemMessage(data.data && data.data.error_message ?  data.data.error_message : "Произошла ошибка в процессе выбора заказа, попробуйте позже", MessageType.Error))
};

export const checkAndTakeOrder = (order_id: string, system: SystemState, dispatch:any)  => {
    let data = checkOrder(order_id, system, dispatch);
    if (data.result){
        takeOrder(order_id, system.driver_id, dispatch);
        return;
    }
    if (data.error_message){
        dispatch(setSystemMessage( data.error_message, MessageType.Error))
    }
};

export const  loadOrdersFromServer = async (id:string, dispatch: any) => {
    return driverAPI.getDataById(id).then( (res) => {
        if (!res.data  ) return;
        dispatch(setDriverData(res.data));
        if (id)
            dispatch(setDriverID(id));
    }).catch((err) => {
        dispatch(setSystemMessage("Ошибка: в процессе получения данных с сервера произошла ошибка, попробуйте перегрузить страницу !", MessageType.Error));
    });
};

export const getCategory = (id: string) => {

    switch (id) {
        case "52b30be1-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30be1-49d5-11e7-9696-e41f13c2b942", maxWeight: 500, image:  imagePath+'/image/500.svg'};
        case "bb0df94d-7dde-11e8-81b0-e41f13c2b942" :
            return {id: "bb0df94d-7dde-11e8-81b0-e41f13c2b942", maxWeight: 1000, image: imagePath +'/image/1000.svg'};
        case "52b30bdd-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30bdd-49d5-11e7-9696-e41f13c2b942", maxWeight: 1500, image: imagePath +'/image/1500.svg'};
        case "52b30bde-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30bdd-49d5-11e7-9696-e41f13c2b942", maxWeight: 1500, image: imagePath +'/image/1500.svg'};
        case "52b30bdc-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30bdc-49d5-11e7-9696-e41f13c2b942", maxWeight: 3000, image: imagePath +'/image/3000.svg'};
        case "52b30bdf-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30bdf-49d5-11e7-9696-e41f13c2b942", maxWeight: 5000, image: imagePath +'/image/5000.svg'};
        case "52b30be0-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30be0-49d5-11e7-9696-e41f13c2b942", maxWeight: 10000, image: imagePath +'/image/10000.svg'};
        case "52b30be2-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30be2-49d5-11e7-9696-e41f13c2b942", maxWeight: 10000, image: imagePath +'/image/10000.svg'};
        case "52b30be4-49d5-11e7-9696-e41f13c2b942" :
            return {id: "52b30be4-49d5-11e7-9696-e41f13c2b942", maxWeight: 20000, image: imagePath +'/image/20000.svg'};
    }
    return {id: "52b30be1-49d5-11e7-9696-e41f13c2b942", maxWeight: 500, image: imagePath +'/image/500.svg'};
};

export const getCargoSize = (order: OrderStruct) => {
    return order.cargo.summary;
    let cargo_size = {weight: 0, height: 0, length: 0, width: 0};
    let sum = (size:{weight: number, height: number, length: number, width: number}) =>
        cargo_size = {weight: cargo_size.weight + size.weight, height: cargo_size.height + size.height, length: cargo_size.length+ size.length, width: cargo_size.width+size.width};

    order.cargo.packages.forEach( x=> sum(x.size));
    order.cargo.pallets.forEach( x=> sum(x.size));
    order.cargo.places.forEach( x=> sum(x.size));
    return cargo_size;
};

export const waitWhileWindowMessageIsOpen = (type: any) => {
    return new  Promise((resolve)=>{

        setInterval(()=>{
            //@ts-ignore
            if (window.showMessageType == type)
                resolve(true);
        }, 100)
    }).then(()=>{
        console.log('found')
        // checkAndTakeOrder(system.was_order_id, system, dispatch);
    });
}

export default systemReducer;
