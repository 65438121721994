export const SET_ORDER_LIST = 'SET_ORDER_LIST';

export enum EnumPayType {
    CASH = "cash",
    CASHLESS= "cashless"
}

export interface OrderStruct {
    id: string,
    date: string,
    car_type_id: string,
    car_type_name: string,
    body_option_id: string,
    adress: string,
    description: string,
    route: string,
    hours_from: number,
    hours_to: number,
    cost_by_hour: number,
    cost: number,
    min_cost: number,
    confirmed: boolean,
    disagree?: boolean,
    pay_date: string,
    pay_type: EnumPayType,
    pay_dates?:{date:string, type:EnumPayType,description:string}[]
    cargo: {
        name: string,
        packages: {
                    package_type_id: string,
                    quantity: number,
                    size: {weight: number, height: number, length: number, width: number}
        }[],
        pallets: {
            pallet_type_id: string,
            quantity: number,
            size: {weight: number, height: number, length: number, width: number}
        }[],
        places: {size: {weight: number, height: number, length: number, width: number}}[],
        summary: {
            weight: number,
            length: number,
            width: number,
            height: number
        }
    }


}

export interface SetOrderListAction {
  type: typeof SET_ORDER_LIST;
  list: OrderStruct[];
}


export type OrdersActionTypes = SetOrderListAction;

export interface OrdersDataState {
    list: OrderStruct[];
}
