export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_DATETIME = 'SET_DATETIME';

export interface CategoryStruct {
    id: string, maxWeight: number, image: string
}

export interface SetCategoryListAction {
  type: typeof SET_CATEGORY_LIST;
  list: CategoryStruct[];
}

export interface SetDateTimeAction {
  type: typeof SET_DATETIME;
  date: string;
}


export type CategoriesActionTypes = SetCategoryListAction | SetDateTimeAction;

export interface CategoriesDataState {
    list: CategoryStruct[];
    date: string;
}
