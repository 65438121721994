//.....................................localhost.................................

const host = window.location.host.replace('www.','').split('.').reverse().splice(0, 2).reverse().join('.');
//const apiHost = host.indexOf('localhost')>=0 ? 'citycarrier.ru' : 'citycarrier.ru';
const apiHost = "progrecctk.ru";
export const apiOrderUrl = `https://api2.${apiHost}/PublicOrdersAPI`;
export const apiDriverUrl = `https://api2.${apiHost}/PublicDriversAPI`;
export const daDataToken = "1c9e4f8ec6a07475edee3f42c3fd6e7e44a3a7ef";//"4907ed3e0ba286c611e621c3db1588fe3ce7f53c";
// export const hereMapApiKey = 'Y0h-TaAfPC5vfGxnxpXZDi6Sqz5j594wIJaBxh0us40';
export const hereMapApiKey = 'MiWaYuPKblM-f_Ih7SIJ07tcDkQJvZt13fSxOKdxy6E';
export const imagePath =  window.location.host == 'localhost:3000' ? '/autosearch' : '.';
export const updatePageTimeInSeconds = 30 * 60; // автообновление страницы каждые пол часат
