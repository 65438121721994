import {OrderStruct} from "../../redux/reducers/orders/@types";



export enum EnumStatusResponse {
  success= "success"
}

export interface ResponseData {
  "name": string,
  "car_type_id": string,
  "body_option_id": string,
  body_size: {length: number, width: number, height: number},
  garage: {adress: string, latitude: number, longitude: number},
  orders: OrderStruct[]
}
