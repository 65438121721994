import {CategoriesActionTypes, CategoriesDataState, SET_CATEGORY_LIST, SET_DATETIME} from "./@types";

let initialState: CategoriesDataState = {
	list: [],
	date: ''
};

const categoriesReducer = (state = initialState, action: CategoriesActionTypes) => {
	switch (action.type) {
		case SET_CATEGORY_LIST:
			return {
				...state,
				list: action.list
			}

		case SET_DATETIME:
			return {
				...state,
				date: action.date
			}

		default:
			return state;
	}
};

export default categoriesReducer;
