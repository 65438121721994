import {
  BodyOptionsType,
  BodySizeType,
  CarTypeType,
  GarageType,
  HIDE_UPDATE_DATA_WINDOW,
  hideUpdateDataWindowAction,
  MessageType,
  SET_BODY_OPTIONS,
  SET_CATEGORIES,
  SET_DRIVER_DATA,
  SET_DRIVER_ID,
  SET_STATE_ORDER,
  SET_SYSTEM_MESSAGE,
  SET_USER_INFO,
  setBodyOptionsAction,
  setDriverDataAction,
  setDriverIDAction,
  setListCategoriesAction,
  setStateOrderAction,
  setSystemMessageAction,
  setUserInfoAction,
  SHOW_UPDATE_DATA_WINDOW,
  showUpdateDataWindowAction,
  TOGGLE_DISAGREE_ORDER,
  TOGGLE_LOADING_WINDOW,
  toggleLoadingWindowAction,
  toggleOrderDisagreeAction,
} from "./@types";
import {ResponseData} from "../../../api/@types/index";

export const setDriverData = (data:ResponseData): setDriverDataAction => ({
  type: SET_DRIVER_DATA,
  data
});

export const setDriverID = (id:string): setDriverIDAction => ({
  type: SET_DRIVER_ID,
  id
});

export const setBodyOptions = (bodies:BodyOptionsType[]): setBodyOptionsAction => ({
  type: SET_BODY_OPTIONS,
  bodies
});

export const setListCategories = (categories:CarTypeType[]): setListCategoriesAction => ({
  type: SET_CATEGORIES,
  categories
});

export const setUserInfo = (garage:GarageType, body_size:BodySizeType): setUserInfoAction => ({
  type: SET_USER_INFO,
  garage,
  body_size
});

export const showUpdateDataWindow = (order_id: string): showUpdateDataWindowAction => ({
  type: SHOW_UPDATE_DATA_WINDOW,
  order_id
});

export const hideUpdateDataWindow = (): hideUpdateDataWindowAction => ({
  type: HIDE_UPDATE_DATA_WINDOW

});


export const toggleLoadingWindow = (show:boolean): toggleLoadingWindowAction => ({
  type: TOGGLE_LOADING_WINDOW,
  show
});

export const setSystemMessage = (message:string, messageType : MessageType): setSystemMessageAction => ({
  type: SET_SYSTEM_MESSAGE,
  message,
  messageType
});
export const setStateOrder = (order_id:string): setStateOrderAction => ({
  type: SET_STATE_ORDER,
  order_id
});

export const toggleOrderDisagree = (order_id:string): toggleOrderDisagreeAction => ({
  type: TOGGLE_DISAGREE_ORDER,
  order_id
});



