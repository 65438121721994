import {ResponseData} from "../../../../api/@types";

export const SET_SYSTEM_MESSAGE = 'SET_SYSTEM_MESSAGE';

export const SET_DRIVER_DATA = "SET_DRIVER_DATA";
export const SET_BODY_OPTIONS = "SET_BODY_OPTIONS";
export const SET_DRIVER_ID = "SET_DRIVER_ID";
export const SHOW_UPDATE_DATA_WINDOW = "SHOW_UPDATE_DATA_WINDOW";
export const HIDE_UPDATE_DATA_WINDOW = "HIDE_UPDATE_DATA_WINDOW";
export const SET_CAR_TYPES = "SET_CAR_TYPES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_USER_INFO = "SET_USER_INFO";
export const TOGGLE_LOADING_WINDOW = "TOGGLE_LOADING_WINDOW";
export const SET_STATE_ORDER = "SET_STATE_ORDER";
export const TOGGLE_DISAGREE_ORDER = "TOGGLE_DISAGREE_ORDER";


export enum MessageType {
  None= "none", Error="error", Info="info", Warning = "warning"
}

export interface CarTypeType{
    id: string,
    name: string,
    length_from: number,
    length_to: number,
    width_from: number,
    width_to: number,
    height_from: number,
    height_to: number,
    weight_from: number,
    weight_to: number,
    area_from: number,
    area_to: number,
    volume_from: number,
    volume_to: number,
    pallets_from: number,
    pallets_to: number,
    pass_sk: boolean,
    pass_ttk: boolean,
    pass_mkad: boolean,
    ramp: boolean,
    tail_lift: boolean,
    board: boolean,
    refrigerator: boolean,
}

export interface BodyOptionsType {
    name: string,
    body_type_id: number,
    id: string
}

export interface setDriverDataAction {
  type: typeof SET_DRIVER_DATA;
  data: ResponseData;
}

export interface setDriverIDAction {
  type: typeof SET_DRIVER_ID;
  id: string;
}

export interface setBodyOptionsAction {
    type: typeof SET_BODY_OPTIONS;
    bodies:BodyOptionsType[];
}

export interface setListCategoriesAction {
    type: typeof SET_CATEGORIES;
    categories:any[];
}
export interface setUserInfoAction {
    type: typeof SET_USER_INFO;
    body_size: BodySizeType,
    garage: GarageType,
}

export interface showUpdateDataWindowAction {
  type: typeof SHOW_UPDATE_DATA_WINDOW;
  order_id: string;
}
export interface hideUpdateDataWindowAction {
  type: typeof HIDE_UPDATE_DATA_WINDOW;
}

export interface toggleLoadingWindowAction {
  type: typeof TOGGLE_LOADING_WINDOW;
  show: boolean;
}

export interface setSystemMessageAction {
  type: typeof SET_SYSTEM_MESSAGE;
  message: string;
  messageType: MessageType;
}
export interface setStateOrderAction {
  type: typeof SET_STATE_ORDER;
  order_id: string;
}

export interface toggleOrderDisagreeAction {
  type: typeof TOGGLE_DISAGREE_ORDER;
  order_id: string;
}



export type SystemActionTypes = setDriverDataAction
    | setSystemMessageAction
    | setDriverIDAction
    | setBodyOptionsAction
    | setUserInfoAction
    | setListCategoriesAction
    | toggleLoadingWindowAction
    | setStateOrderAction
    | toggleOrderDisagreeAction
    | showUpdateDataWindowAction
    | hideUpdateDataWindowAction;

export interface SystemState {
    categories: CarTypeType [],
    update_window_visible:boolean,
    bodies:BodyOptionsType[],
    driver_id:string,
    data: ResponseData | null,
    message:  {text: string, type: MessageType},
    is_loading: boolean
    was_order_id: string
}

export enum EnumDecline {
    NOT_INTERESTING_BY_TIME,
    NOT_INTERESTING_BY_ADDRESS,
    NOT_INTERESTING_IM_BUSY,

}
export enum EnumOrderBookView {
  Today, Future, Past
}

export interface BodySizeType{
    length: number,
    width: number,
    height: number
}

export interface GarageType{
    adress: string,
    latitude: number,
    longitude: number
}
