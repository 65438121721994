import axios from "axios";
import {apiDriverUrl, apiOrderUrl, daDataToken} from "../deployment";
import {EnumDecline} from "../redux/reducers/system/@types";

const dadata = axios.create({
    baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
    headers: {
        Authorization: "Token " + daDataToken,
        ContentType: "application/json",
        Accept: "application/json"
    }
});

const instanceOrder = axios.create({
    baseURL: apiOrderUrl,
});

const instanceDriver = axios.create({
    baseURL: apiDriverUrl
});

export const driverAPI = {
    getDataById(driver_id:string){
        if (driver_id == '8888')
            return new Promise<{data: any}>( resolve => resolve(
                {data:{"name": "Жихорев Виктор Александрович",
                        "car_type_id": "52b30bdd-49d5-11e7-9696-e41f13c2b942",
                        "body_option_id": "ae606bfa-1df7-11e4-9a8e-e41f13c2b943",
                        "orders": [
                            {
                                "id": "95fafda5-ef96-11eb-8ea7-00155d010f14",
                                "date": "2021-07-29T09:00:00",
                                "car_type_id": "52b30be1-49d5-11e7-9696-e41f13c2b942",
                                "car_type_name": "Категория №1",
                                "body_option_id": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03",
                                "adress": "РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12",
                                "description": "ТАРИФЫ ВОДИТЕЛЯ. Часы работы - 7. За час: 545 (5+1) + 1ч. (Экспедирование). Доплаты:  0 (Доставка документов) + 800 (Пропуск СК) + 0 (Страхование груза). \\nТРЕБОВАНИЯ К А/М. Базовый тип автомобиля: Категория №3. Вариант исполнения: ЗАКРЫТЫЙ ТИП КУЗОВА. Пропуск СК. Километры в МКАД: >= 30,64 . \\nОПИСАНИЕ ГРУЗА. Экспедирование. \\nВРЕМЯ ПОДАЧИ. 09:00. \\nМАРШРУТ. РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12\\n - РОССИЯ, 101000, Москва г, Колпачный пер, дом 5, строение 3\\n - РОССИЯ, 119019, Москва г, Кремлевская наб, дом 1\\n - РОССИЯ, Москва г, Софийская наб, дом 22\\n - РОССИЯ, 125167, Москва г, Ленинградский пр-кт, дом 36. \\n",
                                "hours_from": 4,
                                "hours_to": 5,
                                "cost_by_hour": 545,
                                "cost": 4615,
                                "min_cost": 4070,
                                "confirmed": false,
                                route:"http://yandex.ru"
                            },

                            {
                                "id": "95fafda5-ef96-11eb-8ea7-00155d010f11",
                                "date": "2021-07-29T11:30:00",
                                "car_type_id": "52b30bdc-49d5-11e7-9696-e41f13c2b942",
                                "car_type_name": "Категория №3",
                                "body_option_id": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03",
                                "adress": "РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12",
                                "description": "ТАРИФЫ ВОДИТЕЛЯ. Часы работы - 7. За час: 545 (5+1) + 1ч. (Экспедирование). Доплаты:  0 (Доставка документов) + 800 (Пропуск СК) + 0 (Страхование груза). \\nТРЕБОВАНИЯ К А/М. Базовый тип автомобиля: Категория №3. Вариант исполнения: ЗАКРЫТЫЙ ТИП КУЗОВА. Пропуск СК. Километры в МКАД: >= 30,64 . \\nОПИСАНИЕ ГРУЗА. Экспедирование. \\nВРЕМЯ ПОДАЧИ. 09:00. \\nМАРШРУТ. РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12\\n - РОССИЯ, 101000, Москва г, Колпачный пер, дом 5, строение 3\\n - РОССИЯ, 119019, Москва г, Кремлевская наб, дом 1\\n - РОССИЯ, Москва г, Софийская наб, дом 22\\n - РОССИЯ, 125167, Москва г, Ленинградский пр-кт, дом 36. \\n",
                                "hours_from": 4,
                                "hours_to": 5,
                                "cost_by_hour": 545,
                                "cost": 4615,
                                "min_cost": 4070,
                                "confirmed": false

                            },

                            {
                                "id": "95fafda5-ef96-11eb-8ea7-00155d010f22",
                                "date": "2021-07-29T09:00:00",
                                "car_type_id": "52b30bdc-49d5-11e7-9696-e41f13c2b942",
                                "car_type_name": "Категория №3",
                                "body_option_id": "1ac6a159-f0f7-11db-9d25-000cf16cef9c",
                                "adress": "РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12",
                                "description": "ТАРИФЫ ВОДИТЕЛЯ. Часы работы - 7. За час: 545 (5+1) + 1ч. (Экспедирование). Доплаты:  0 (Доставка документов) + 800 (Пропуск СК) + 0 (Страхование груза). \\nТРЕБОВАНИЯ К А/М. Базовый тип автомобиля: Категория №3. Вариант исполнения: ЗАКРЫТЫЙ ТИП КУЗОВА. Пропуск СК. Километры в МКАД: >= 30,64 . \\nОПИСАНИЕ ГРУЗА. Экспедирование. \\nВРЕМЯ ПОДАЧИ. 09:00. \\nМАРШРУТ. РОССИЯ, 140014, Московская обл, Люберцы г, Хлебозаводская ул, дом 12\\n - РОССИЯ, 101000, Москва г, Колпачный пер, дом 5, строение 3\\n - РОССИЯ, 119019, Москва г, Кремлевская наб, дом 1\\n - РОССИЯ, Москва г, Софийская наб, дом 22\\n - РОССИЯ, 125167, Москва г, Ленинградский пр-кт, дом 36. \\n",
                                "hours_from": 4,
                                "hours_to": 5,
                                "cost_by_hour": 545,
                                "cost": 4615,
                                "min_cost": 4070,
                                "confirmed": false
                            }
                        ]}})
            );

       return  instanceDriver.get(`/reserve-orders?driver_id=${driver_id}`)
               .then(response => response)
               .catch(error => error);
    },

    takeOrder(order_id:string, driver_id:string){
        let body = {
            "driver_id": driver_id,
            "order_id": order_id,
            "confirmed": true,
            "bad_orders": false,
            "busy": false
        };

        return instanceDriver.post('/reserve-driver', body )
    },

    notInteresting(driver_id:string, reason: EnumDecline){
        let body = {
            "driver_id": driver_id,
            "order_id": "",
            "confirmed": false,
            "bad_orders": reason == EnumDecline.NOT_INTERESTING_BY_ADDRESS,
            "bad_orders_by_time": reason == EnumDecline.NOT_INTERESTING_BY_TIME,
            "busy": reason == EnumDecline.NOT_INTERESTING_IM_BUSY
        };

        return instanceDriver.post('/reserve-driver', body )
                .then(response => response)
                .catch(error => error);
    },


    updateData(param :{car_type_id:string, body_option_id:string, phone?: string, name?:string, length:number, width:number,height:number, adress:string, longitude: number, latitude:number, driver_id:string }){
       let body = {
            "driver_id": param.driver_id,
            "car_type_id": param.car_type_id,
            "body_option_id": param.body_option_id,
            "size": {
                        "length": param.length,
                        "width": param.width,
                        "height": param.height
                    },
            "adress": {
                "adress": param.adress,
                "longitude": param.longitude,
                "latitude": param.latitude
            },
       };
       //@ts-ignore
       if (param.phone) body.phone = param.phone;
       //@ts-ignore
       if (param.name) body.name = param.name;

        return instanceDriver.post('/reserve-update-data?driver_id='+param.driver_id, body )
            .then(response => response)
            .catch(error => error);
    }




};

export const orderAPI = {
    getCategoriesList() {
        return instanceDriver.get('/car-types' )
            .then(response => response)
            .catch(error => error);
    },
    getBodyOptionsList() {
        return instanceDriver.get('/body-options' )
            .then(response => response)
            .catch(error => error);
    },
    getCategoriesFull() {
        return instanceOrder.get('/car-types-tree' )
            .then(response => response)
            .catch(error => error);
    }
};

export const addressAPI = {
    getAddressByCoords(lat: string, lon: string) {
        let requestData = {lat, lon};
        return dadata.post('/geolocate/address', requestData)
            .then(response => response)
            .catch(error => error);
    },
};
